
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ContainerItem',
  props: {
    img: { type: String, required: true },
    name: { type: String, required: true },
    rarity: { type: Number, required: true }
  },
  setup (props) {
    console.log('props from item:', props.rarity)
    return { }
  }
})
