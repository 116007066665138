
import { defineComponent, reactive, Ref, ref } from 'vue'
import ContainerItem from '@/components/containerItem.vue'
import ContainerContent from '@/components/containerContent.vue'
import caseData from '@/testData/containers.json'
import knives from '@/testData/knives.json'
import gloves from '@/testData/gloves.json'

export default defineComponent({
  name: 'CaseOpener',
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  components: {
    ContainerItem,
    ContainerContent
  },

  setup (props) {
    console.log(props.slug)

    const randomNumber = (min: number, max: number) => { // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min)
    }

    const getContainer = (containerName: string): Record<string, any> => {
      for (let i = 0; i < caseData.length; i++) {
        if (caseData[i] && containerName === caseData[i].name) {
          return caseData[i]
        }
      }
      throw new Error('CASE NOT FOUND')
    }

    const getRarity = () => {
      const rarity = randomNumber(1, 10000000)

      const special_chance = 25575
      const covert_chance = special_chance + 63939
      const classified_chance = covert_chance + 319693
      const restricted_chance = classified_chance + 1598465

      if (rarity <= special_chance) return 0 // 'Special'
      if (rarity <= covert_chance) return 1 // 'Covert'
      if (rarity <= classified_chance) return 2 // 'Classified'
      if (rarity <= restricted_chance) return 3 // 'Restricted'
      else return 4 // 'Mil-Spec'
    }

    const createContainerItems = (containerContent: any[][], amount: number, unboxed?: Record<string, any>) => {
      const elements = []

      for (let i = 0; i < amount; i++) {
        if (unboxed && i === unboxed.index) {
          elements.push(unboxed.skin)
        } else {
          const rarity = getRarity()
          const possibleSkins = containerContent[rarity]
          const skin = possibleSkins[(randomNumber(1, possibleSkins.length) - 1)]
          skin.rarity = rarity
          elements.push(skin)
        }
      }

      console.log('full container: ', elements)
      return elements
    }

    const getAllDifferentContainerItems = (containerContent: any[][]): Record<string, any>[] => {
      const lllll: Record<string, any>[] = []
      containerContent.forEach((condition: any[], index: number) => {
        if (index === 0) {
          const specialItem = {
            name: '★ Rare Special Item ★',
            icon: 'https://vignette4.wikia.nocookie.net/cswikia/images/a/ad/Csgo-default_rare_item.png/revision/latest?cb=20150227163025',
            rarity: 0
          }
          lllll.push(specialItem)
        } else {
          condition.forEach(skin => {
            skin.rarity = index
            skin.icon = 'https://steamcommunity-a.akamaihd.net/economy/image/' + skin.icon
            lllll.push(skin)
          })
        }
      })
      return lllll
    }

    const container = getContainer(props.slug)
    const allDifferentItemsInThisContainer = getAllDifferentContainerItems(container.content).reverse()

    const openedSkins: Ref<any[]> = ref([])
    const containerItems: Ref<any[]> = ref([])
    const openingContainer = ref(false)
    const skinWinner = ref(false)
    const contentShowcase = ref(true)

    const data = reactive({
      totalCasesOpened: 0,
      spent: 0,
      earned: 0,
      unboxedSkin: {},

      amountRarity6: 0,
      amountRarity5: 0,
      amountRarity4: 0,
      amountRarity3: 0,
      amountRarity2: 0,
      amountRarity1: 0,
      amountRarity0: 0,

      floatDecimal: 6,
      spinTime: 7000
    })

    const updateFloatLength = () => {
      if (data.floatDecimal < 12) data.floatDecimal = 16
      else data.floatDecimal = 6
    }

    const chooseItem = (containerContent: any[]) => {
      const rarity = getRarity()
      const statTrak = isStatTrak()

      let skin
      if (rarity === 0) {
        window.alert('jz wirds gelb')
        if (containerContent[0].name === '★ Gloves ★') {
          skin = gloves[(randomNumber(1, gloves.length) - 1)]
        } else {
          if (statTrak) {
            skin = knives[(randomNumber((knives.length / 2), (knives.length)) - 1)]
          } else {
            skin = knives[(randomNumber(1, (knives.length / 2)) - 1)]
          }
        }
        skin.icon = 'https://steamcommunity-a.akamaihd.net/economy/image/' + skin.icon
      } else {
        const possibleSkins = containerContent[rarity]
        skin = possibleSkins[(randomNumber(1, possibleSkins.length) - 1)]
      }
      console.log('icon normal', skin.icon)

      const float = createFloat(skin.min, skin.max)
      const condition = getCondition(float)

      let special
      let price
      let icon_id

      if (statTrak) {
        special = 'StatTrak™'
        if (skin.conditions[condition]) {
          price = skin.conditions[condition].price
        } else {
          console.log('we have problem husten', skin)
          price = skin.conditions['Not Painted'].price
        }
        icon_id = skin.icon
      } else {
        if (skin.conditions[condition]) {
          price = skin.conditions[condition].price
        } else {
          console.log('we have problem husten', skin)
          price = skin.conditions['Not Painted'].price
        }
        icon_id = skin.icon
      }

      return {
        name: skin.name,
        rarity: rarity,
        case: container.name,
        float: float,
        condition: condition,
        price,
        icon: icon_id,
        full_icon_url: `https://steamcommunity-a.akamaihd.net/economy/image/${icon_id}`,
        special
      }
    }

    const unbox = () => {
      const containerContent = container.content
      contentShowcase.value = false
      skinWinner.value = false
      openingContainer.value = true

      const unboxedSkin = chooseItem(containerContent)
      console.log(unboxedSkin)

      containerItems.value = createContainerItems(containerContent, 50, { skin: unboxedSkin, index: 45 })

      startRoll(container.name)

      data.unboxedSkin = unboxedSkin

      setTimeout(() => {
        data.totalCasesOpened++
        openedSkins.value.push(unboxedSkin)
        data.spent += (220 + container.price)
        data.earned += unboxedSkin.price
        updateRarityAmount(unboxedSkin.rarity)
      }, data.spinTime)

      return { unboxedSkin }
    }

    const updateRarityAmount = (rarity: number) => {
      switch (rarity) {
        case 6:
          data.amountRarity6++
          break
        case 5:
          data.amountRarity5++
          break
        case 4:
          data.amountRarity4++
          break
        case 3:
          data.amountRarity3++
          break
        case 2:
          data.amountRarity2++
          break
        case 1:
          data.amountRarity1++
          break
        case 0:
          data.amountRarity0++
          break
        default:
          window.alert('alarm im darm!')
          break
      }
    }

    function startRoll (containerName: string) {
      const openCaseBtn = document.querySelector('#openContainer')
      if (openCaseBtn) openCaseBtn.innerHTML = 'Rolling ...'

      const lineMin = 6960
      const lineMax = 7120
      const landLine = randomNumber(lineMin, lineMax)
      console.log(landLine)

      const boxV2 = document.querySelector('#items')
      if (boxV2) {
        setTransition((boxV2 as HTMLElement))
        // setTimeout(() => { (boxV2 as HTMLElement).style.marginLeft = '-' + landLine + 'rem' }, 50)
        setTimeout(() => { (boxV2 as HTMLElement).style.marginLeft = '-' + landLine + 'px' }, 50)

        setTimeout(() => {
          if (openCaseBtn) {
            openCaseBtn.innerHTML = 'Open ' + containerName
            resetToZero(boxV2 as HTMLElement)
          }
          displaySkin()
        }, data.spinTime)
      }
    }

    const setTransition = (element: HTMLElement) => {
      element.style.transition = data.spinTime - 1000 + 'ms'
      element.style.transitionTimingFunction = 'cubic-bezier(0,0.11,0.33,1)'
    }

    const resetToZero = (box: HTMLElement) => {
      box.style.transition = '0s'
      box.style.marginLeft = '25px'
    }

    const displaySkin = () => {
      console.log('we are displaying opnened skin')
      openingContainer.value = false
      skinWinner.value = true
    }

    const getCondition = (float: number): string => {
      if (float < 0.07) return 'Factory New'
      if (float < 0.15) return 'Minimal Wear'
      if (float < 0.38) return 'Field-Tested'
      if (float < 0.45) return 'Well-Worn'
      if (float < 1) return 'Battle-Scarred'
      else return 'FUCK YOU'
    }

    const getShortCondition = (condition: string): string => {
      switch (condition) {
        case 'Factory New':
          return 'FN'
        case 'Minimal Wear':
          return 'MW'
        case 'Field-Tested':
          return 'FT'
        case 'Well-Worn':
          return 'WW'
        case 'Battle-Scarred':
          return 'BS'
      }
      return 'F'
    }

    const isStatTrak = (): boolean => {
      const statTrakChance = randomNumber(1, 10)
      if (statTrakChance === 10) return true
      return false
    }

    const createFloat = (minFloat: number, maxFloat: number): number => {
      return Math.random() * (maxFloat - minFloat) + minFloat
    }

    return { data, unbox, container, openedSkins, containerItems, allDifferentItemsInThisContainer, openingContainer, skinWinner, contentShowcase, updateFloatLength, getShortCondition }
  }
})
