
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ContainerContent',
  props: {
    img: { type: String, required: true },
    name: { type: String, required: true },
    rarity: { type: Number, required: true }
  },
  setup (props) {
    console.log('rarity:', props.rarity)
    const weapon = props.name.split(' | ')[0]
    const skin = props.name.split(' | ')[1]
    return { weapon, skin }
  }
})
