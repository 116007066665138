
import { defineComponent } from 'vue'
import caseData from '@/testData/containers.json'

// create componement for Actually opening case
export default defineComponent({
  name: 'Case',

  data () {
    const openableContainers: any[] = caseData
    return { openableContainers }
  },

  methods: {
    displayContainerPage (container: any) {
      this.$router.push({ path: `/case/${container.name}` })
    }
  }
})
